.app {
  background-color: #f2f2f2;
}

.leaflet-container {
  background: #fff;
  outline: 0;
}

.react-numeric-input > b {
  background-color: white !important;
  border-radius: 100px !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
  color: red !important;
}

.react-numeric-input > b > i {
  background: #003a5d !important;
}

.react-numeric-input > textarea:focus,
input:focus {
  outline: none;
}

.leaflet-control-attribution {
 display: none; 
}

.leaflet-control-container-custom { 
  position: absolute; 
  right: 0;
  top:50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.leaflet-disabled-custom {
  cursor: default;
  background-color: #d0d0d0;
  color: #bbb;
  pointer-events: none;
}

.leaflet-control-container-custom img {
  margin: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.rbc-current-time-indicator {
  opacity: 0;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
  background-clip: padding-box;
}

.leaflet-tooltip {
  display: none;
}

.leaflet-marker-icon  {
  outline: none !important;
}

.MuiSelect-icon {
  margin-right: 12px;
  margin-top: -2px;
}


.rbc-today {
  background-color: white;
}

.rbc-timeslot-group {
  border-color: #E4E9EC;
}

.rbc-label {
  color: #809DAF;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-header-cell {
  display: flex;
  align-items: center;
  height: 50px;
}

.rbc-header {
  border: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-time-view {
  border: none;
}

.rbc-time-slot {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 8px;
  border: none !important;
}

.rbc-time-column {
  border-bottom: none !important;
}

.rbc-header button span {
    display: none;
}

.MuiSelect-icon {
  margin-right: 12px;
  margin-top: -2px;
}

.react-tooltip-extra {
  opacity: 1 !important;
  border-radius: 5px;
  border: 1px solid red;
}

input:-webkit-autofill {
  border-radius: 12px 0 0 12px !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
 border-radius: 0 !important;
} 

.div-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
}

.div-icon div {
  background-color: #ffffff9e;
  border-radius: 4px;
  padding: 0 4px;
  margin-top: -1px;
  font-weight: normal;
  font-size: 10px;
}

.PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.MuiList-root {
  padding-top: 0 !important;
}

.MuiDrawer-root > .MuiPaper-root {
  overflow: visible;
}

.flexbox-fix > div:nth-child(2) > div:nth-child(2) {
  display: none;
}